<template>
    <!-- <div class="block">
        <div>
            <img src="@/assets/img/logo.png" />
            <h3>欢迎使用智工邦</h3>
            <p>智慧劳务信息化管理软件</p>
            <div class="login" @click="is_click ? '' : login()">微信账号一键登录</div>
        </div>
    </div> -->
    <div class="block">
        <div class="box">
            <div class="title">找<span>靠谱高薪</span>好工作</div>
            <div class="tip">好厂不等人，点击登录马上上车</div>
        </div>
        <div class="bg">
            <img src="@/assets/img/login_bg.png" alt="">
        </div>
        <div class="btn">
            <div @click="is_click ? '' : login()">微信账号一键登录</div>
            <!-- <span class="btn-tip">智工邦提供技术支持</span> -->
        </div>
    </div>

</template>
<script>
var u = navigator.userAgent
var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
if (isIOS) {
    window.onpageshow = function (e) {
        if (
            e.persisted ||
            (window.performance && window.performance.navigation.type == 2)
        ) {
            window.location.reload();
        }
    };
}
export default {
    data() {
        return {
            is_click: false, // 是否点击过了登录
            is_form: ''
        }
    },
    activated() {
        this.$store.commit("hideTabbar");
    },
    mounted() {
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        this.getUrl()
    },
    methods: {
        getUrl() {
            let query = ''
            let list = Object.keys(this.$route.query)
            // 只有有参数的时候才会进来：如入职码、借支码、离职码这些直接进入login页的
            if (list.length) {
                for (let key in this.$route.query) {
                    if (list[list.length - 1] == key) {
                        query += key + '=' + this.$route.query[key]
                    } else {
                        query += key + '=' + this.$route.query[key] + "&"
                    }
                }
                if (query.indexOf('type=' > -1)) {
                    query = query.replace('type=', '')
                }
                localStorage.setItem('is_token', query) // 后期要跳转的连接
            }
        },
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        // 登录
        login() {
            this.is_click = true
            var type = 'empty'
            if (localStorage.getItem('is_token').indexOf('verify') > -1) {
                type = localStorage.getItem('is_token')
            }
            this.Request('wechat/auth/url', 'get', { redirect_url: process.env.VUE_APP_BASE_URL + type }, false).then(res => {
                if (res.status == 0) {
                    localStorage.removeItem('token');
                    location.replace(res.data.data.wechat_auth_url);
                } else {
                    this.is_click = false
                }
            })
        }
    }
}
</script>
<style  scoped>
.block {
    position: relative;
    background: #fff;
    text-align: center;
    height: 100vh;
    box-sizing: border-box;
    align-items: center;
    padding-top: 79px;
    overflow: hidden;
}

.block .box .title {
    font-size: 40px;
    color: #1a1a1a;
    font-weight: bold;
    letter-spacing: 5px;
}

.box .title span {
    font-size: 40px;
    color: #0abbb9;
    letter-spacing: 5px;
}

.box .tip {
    margin-top: 15px;
    color: #999;
    font-size: 26px;
}

.bg {
    padding-top: 80px ;
    padding-bottom:100px ;
    padding-left: 10px !important;
    width: 524px;
    height: 657px;
}

.bg img {
    width: 90%;
}

.block > div {
    margin: auto;
}

.btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120px;
    width: 670px;
    height: 80px;
    line-height: 80px;
    color: #fff;
    font-size: 28px;
    font-weight: 400;
    background: #0abbb9;
    opacity: 1;
    text-align: center;
    border-radius: 10px;
}

.btn-tip{
    display: inline-block;
    padding-top: 5px;
    color: #999999;
    font-size: 22px;
    letter-spacing: 3px;
}

.block h3 {
    font-size: 40px;
}
.block p {
    font-size: 30px;
    margin: 10px 0;
}
.login {
    width: 500px;
    text-align: center;
    background: #4c94f6;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    margin: 100px auto 0;
    color: #fff;
    border-radius: 40px;
}
</style>